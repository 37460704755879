/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { create as r } from "../../core/libs/gl-matrix-2/factories/vec3f64.js";
import { projectBuffer as o } from "./projectBuffer.js";
function t(r, t, f, c, i, m) {
  return e[0] = r, e[1] = t, e[2] = f, o(e, c, 0, i, m, 0, 1);
}
const e = r();
export { t as projectXYZToVector };