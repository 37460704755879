/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import n from "../core/Error.js";
import { throwIfAborted as e, waitTick as r } from "../core/promiseUtils.js";
import { trackAccess as t } from "../core/accessorSupport/tracking.js";
import { SimpleObservable as l } from "../core/accessorSupport/tracking/SimpleObservable.js";
import { create as o } from "../core/libs/gl-matrix-2/factories/vec3f64.js";
import { isSphericalECEF as i, isSphericalPCPF as u } from "./ellipsoidUtils.js";
import s from "./Extent.js";
import a from "./Multipoint.js";
import { l as c, p as f, i as p } from "../chunks/pe.js";
import m from "./Point.js";
import h from "./Polygon.js";
import g from "./Polyline.js";
import R from "./SpatialReference.js";
import { projectBuffer as j } from "./projection/projectBuffer.js";
import { getProjector as y, projectorCache as d } from "./projection/projectors.js";
import { projectXYZToVector as x } from "./projection/projectXYZToVector.js";
import w from "./support/GeographicTransformation.js";
import { equals as z, isValid as M, isWGS84 as Z, isWebMercator as v, isCGCS2000 as A } from "./support/spatialReferenceUtils.js";
import { getGeometryZScaler as S } from "./support/zscale.js";
let G = null,
  P = null,
  b = null,
  E = {};
const T = new l();
function _() {
  return !!G && p();
}
function k() {
  return !!_() || (t(T), W(), !1);
}
function B(n, e) {
  return !n || !e || J(n, e) || k();
}
function U(n, e) {
  return !J(n, e) && !_();
}
function W(n) {
  return null == b && (b = Promise.all([c(), import("../chunks/geometryEngineBase.js").then(n => n.g), import("./geometryAdapters/hydrated.js")])), b.then(([, r, {
    hydratedAdapter: t
  }]) => {
    e(n), P = t, G = r.default, G._enableProjection(f), T.notify();
  });
}
function K(n, e, r = null, t = null) {
  return Array.isArray(n) ? 0 === n.length ? [] : O(P, n, n[0].spatialReference, e, r, t) : O(P, [n], n.spatialReference, e, r, t)[0];
}
function O(n, e, r, t, l = null, o = null) {
  if (null == r || null == t) return e;
  if (J(r, t, l)) return e.map(n => Q(n, r, t));
  if (null == l && i(r)) {
    return e.map(n => Q(n, r, R.WGS84)).map(n => H(n, t));
  }
  if (null == l && i(t)) {
    return e.map(n => H(n, R.WGS84)).map(n => Q(n, R.WGS84, t));
  }
  if (null == l) {
    const n = w.cacheKey(r, t);
    void 0 !== E[n] ? l = E[n] : (null == (l = Y(r, t, void 0)) && (l = new w()), E[n] = l);
  }
  if (null == G || null == n) throw new C();
  return null != o ? G._project(n, e, r, t, l, o) : G._project(n, e, r, t, l);
}
function V(n, e) {
  const r = X([n], e);
  return null != r.pending ? {
    pending: r.pending,
    geometry: null
  } : null != r.geometries ? {
    pending: null,
    geometry: r.geometries[0]
  } : {
    pending: null,
    geometry: null
  };
}
function X(n, e) {
  if (!_()) for (const r of n) if (null != r && !z(r.spatialReference, e) && M(r.spatialReference) && M(e) && !J(r.spatialReference, e)) return t(T), {
    pending: W(),
    geometries: null
  };
  return {
    pending: null,
    geometries: n.map(n => null == n ? null : z(n.spatialReference, e) ? n : M(n.spatialReference) && M(e) ? H(n, e) : null)
  };
}
function Y(n, e, r = null) {
  if (null == n || null == e) return null;
  if (null == G || null == P) throw new C();
  const t = G._getTransformation(P, n, e, r, r?.spatialReference);
  return null !== t ? w.fromGE(t) : null;
}
function q(n, e, r = null) {
  if (null == G || null == P) throw new C();
  const t = G._getTransformationBySuitability(P, n, e, r, r?.spatialReference);
  if (null !== t) {
    const n = [];
    for (const e of t) n.push(w.fromGE(e));
    return n;
  }
  return [];
}
class C extends n {
  constructor() {
    super("projection:not-loaded", "projection engine not fully loaded yet, please call load()");
  }
}
function D() {
  G = null, P = null, b = null, E = {};
}
const F = {
  get loadPromise() {
    return b;
  }
};
function H(n, e) {
  try {
    const r = K(n, e);
    if (null == r) return null;
    "xmin" in n && "xmin" in r && (r.zmin = n.zmin, r.zmax = n.zmax);
    const t = S(r.type, n.spatialReference, e);
    return null != t && t(r), r;
  } catch (r) {
    if (!(r instanceof C)) throw r;
    return null;
  }
}
async function I(n, e, r) {
  const t = n.spatialReference;
  return null != t && null != e && (await N(t, e, null, r)), H(n, e);
}
function J(n, e, r) {
  return !r && (!!z(n, e) || M(n) && M(e) && !!y(n, e, d));
}
function L(n, e) {
  if (z(n, e)) return !0;
  if (!M(n) || !M(e)) return !1;
  const r = Z(n) || v(n) || A(n),
    t = Z(e) || v(e) || A(e);
  return r && t;
}
async function N(n, e, t, l) {
  if (_()) return r(l);
  if (Array.isArray(n)) {
    for (const {
      source: r,
      dest: o,
      geographicTransformation: i
    } of n) if (r && o && !J(r, o, i)) return W(l);
  } else if (n && e && !J(n, e, t)) return W(l);
  return r(l);
}
function Q(n, e, r) {
  return n ? "x" in n ? nn(n, e, new m(), r, 0) : "xmin" in n ? an(n, e, new s(), r, 0) : "rings" in n ? un(n, e, new h(), r, 0) : "paths" in n ? ln(n, e, new g(), r, 0) : "points" in n ? rn(n, e, new a(), r, 0) : null : null;
}
function $(n, e, r = e.spatialReference, t = 0) {
  return null != r && null != n.spatialReference && null != nn(n, n.spatialReference, e, r, t);
}
function nn(n, e, r, t, l) {
  fn[0] = n.x, fn[1] = n.y;
  const o = n.z;
  return fn[2] = void 0 !== o ? o : l, j(fn, e, 0, fn, t, 0, 1) ? (r.x = fn[0], r.y = fn[1], r.spatialReference = t, void 0 !== o || u(t) ? (r.z = fn[2], r.hasZ = !0) : (r.z = void 0, r.hasZ = !1), void 0 === n.m ? (r.m = void 0, r.hasM = !1) : (r.m = n.m, r.hasM = !0), r) : null;
}
function en(n, e, r = e.spatialReference, t = 0) {
  return null != n.spatialReference && null != r && null != rn(n, n.spatialReference, e, r, t);
}
function rn(n, e, r, t, l) {
  const {
      points: o,
      hasZ: i,
      hasM: s
    } = n,
    a = [],
    c = o.length,
    f = [];
  for (const u of o) f.push(u[0], u[1], i ? u[2] : l);
  if (!j(f, e, 0, f, t, 0, c)) return null;
  const p = i || u(t);
  for (let u = 0; u < c; ++u) {
    const n = 3 * u,
      e = f[n],
      r = f[n + 1];
    p && s ? a.push([e, r, f[n + 2], o[u][3]]) : p ? a.push([e, r, f[n + 2]]) : s ? a.push([e, r, o[u][2]]) : a.push([e, r]);
  }
  return r.points = a, r.spatialReference = t, r.hasZ = i, r.hasM = s, r;
}
function tn(n, e, r = e.spatialReference, t = 0) {
  return null != n.spatialReference && null != r && null != ln(n, n.spatialReference, e, r, t);
}
function ln(n, e, r, t, l) {
  const {
      paths: o,
      hasZ: i,
      hasM: s
    } = n,
    a = [];
  if (!cn(o, i ?? !1, s ?? !1, e, a, t, l)) return null;
  const c = i || u(t);
  return r.paths = a, r.spatialReference = t, r.hasZ = c, r.hasM = s, r;
}
function on(n, e, r = e.spatialReference, t = 0) {
  return null != n.spatialReference && null != r && null != un(n, n.spatialReference, e, r, t);
}
function un(n, e, r, t, l) {
  const {
      rings: o,
      hasZ: i,
      hasM: s
    } = n,
    a = [];
  if (!cn(o, i ?? !1, s ?? !1, e, a, t, l)) return null;
  const c = i || u(t);
  return r.rings = a, r.spatialReference = t, r.hasZ = c, r.hasM = s, r;
}
function sn(n, e, r = e.spatialReference, t = 0) {
  return null != n.spatialReference && null != r && null != an(n, n.spatialReference, e, r, t);
}
function an(n, e, r, t, l) {
  const {
      xmin: o,
      ymin: i,
      xmax: s,
      ymax: a,
      hasZ: c,
      hasM: f
    } = n,
    p = c ? n.zmin : l;
  if (!x(o, i, p, e, fn, t)) return null;
  const m = c || u(t);
  r.xmin = fn[0], r.ymin = fn[1], m && (r.zmin = fn[2]);
  const h = c ? n.zmax : l;
  return x(s, a, h, e, fn, t) ? (r.xmax = fn[0], r.ymax = fn[1], m && (r.zmax = fn[2]), f && (r.mmin = n.mmin, r.mmax = n.mmax), r.spatialReference = t, r) : null;
}
function cn(n, e, r, t, l, o, i = 0) {
  const s = new Array();
  for (const u of n) for (const n of u) s.push(n[0], n[1], e ? n[2] : i);
  if (!j(s, t, 0, s, o, 0, s.length / 3)) return !1;
  let a = 0;
  l.length = 0;
  const c = e || u(o);
  for (const u of n) {
    const n = new Array();
    for (const e of u) c && r ? n.push([s[a++], s[a++], s[a++], e[3]]) : c ? n.push([s[a++], s[a++], s[a++]]) : r ? (n.push([s[a++], s[a++], e[2]]), a++) : (n.push([s[a++], s[a++]]), a++);
    l.push(n);
  }
  return !0;
}
const fn = o();
export { J as canProjectWithoutEngine, Y as getTransformation, q as getTransformations, N as initializeProjection, L as isEqualBaseGCS, _ as isLoaded, k as isLoadedOrLoad, B as isLoadedOrLoadFor, W as load, K as project, sn as projectExtent, O as projectMany, en as projectMultipoint, V as projectOrLoad, X as projectOrLoadMany, $ as projectPoint, on as projectPolygon, tn as projectPolyline, I as projectWithZConversion, Q as projectWithoutEngine, U as requiresLoad, F as test, H as tryProjectWithZConversion, D as unload };